export const ACTIONS = {
  DISPLAY_ALERT: "SHOW_ALERT",
  CLEAR_ALERT: "CLEAR_ALERT",

  SETUP_USER_BEGIN: "SETUP_USER_BEGIN",
  SETUP_USER_SUCCESS: "SETUP_USER_SUCCESS",
  SETUP_USER_ERROR: "SETUP_USER_ERROR",

  LOGOUT_USER: "LOGOUT_USER",

  UPDATE_USER_BEGIN: "UPDATE_USER_BEGIN",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",

  DELETE_USER_BEGIN: "DELETE_USER_BEGIN",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",

  HANDLE_CHANGE: "HANDLE_CHANGE",
  HANDLE_PLAYLIST_CHANGE: "HANDLE_PLAYLIST_CHANGE",
  CLEAR_VALUES: "CLEAR_VALUES",

  CREATE_ANIME_BEGIN: "CREATE_ANIME_BEGIN",
  CREATE_ANIME_SUCCESS: "CREATE_ANIME_SUCCESS",
  CREATE_ANIME_ERROR: "CREATE_ANIME_ERROR",

  GET_ANIMES_BEGIN: "GET_ANIMES_BEGIN",
  GET_ANIMES_SUCCESS: "GET_ANIMES_SUCCESS",
  GET_ANIMES_ERROR: "GET_ANIMES_ERROR",

  FETCH_ANIMES_BEGIN: "FETCH_ANIMES_BEGIN",
  FETCH_ANIMES_SUCCESS: "FETCH_ANIMES_SUCCESS",
  FETCH_ANIMES_ERROR: "FETCH_ANIMES_ERROR",

  RESET_FETCHED_ANIMES: "RESET_FETCHED_ANIMES",

  GET_PLAYLIST_BEGIN: "GET_PLAYLIST_BEGIN",
  GET_PLAYLIST_SUCCESS: "GET_PLAYLIST_SUCCESS",
  GET_PLAYLIST_ERROR: "GET_PLAYLIST_ERROR",

  UPDATE_PLAYLIST_BEGIN: "UPDATE_PLAYLIST_BEGIN",
  UPDATE_PLAYLIST_SUCCESS: "UPDATE_PLAYLIST_SUCCESS",
  UPDATE_PLAYLIST_ERROR: "UPDATE_PLAYLIST_ERROR",

  CREATE_PLAYLIST_BEGIN: "CREATE_PLAYLIST_BEGIN",
  CREATE_PLAYLIST_SUCCESS: "CREATE_PLAYLIST_SUCCESS",
  CREATE_PLAYLIST_ERROR: "CREATE_PLAYLIST_ERROR",

  DELETE_ANIME_BEGIN: "DELETE_ANIME_BEGIN",
  DELETE_ANIME_SUCCESS: "DELETE_ANIME_SUCCESS",

  DELETE_PLAYLIST_BEGIN: "DELETE_PLAYLIST_BEGIN",
  DELETE_PLAYLIST_SUCCESS: "DELETE_PLAYLIST_SUCCESS",
  DELETE_PLAYLIST_ERROR: "DELETE_PLAYLIST_ERROR",

  CLEAR_FILTERS: "CLEAR_FILTERS",
  CHANGE_PAGE: "CHANGE_PAGE",

  CHANGE_SITE_LANGUAGE: "CHANGE_SITE_LANGUAGE",

  CHANGE_DEFAULT_PLAYLIST_POLICY: "CHANGE_DEFAULT_PLAYLIST_POLICY",

  CHANGE_THEME: "CHANGE_THEME",
};
