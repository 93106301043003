import A1 from "./A1.webp";
import A2 from "./A2.webp";
import A3 from "./A3.webp";
import A4 from "./A4.webp";
import A5 from "./A5.webp";
import A6 from "./A6.webp";
import A7 from "./A7.webp";
import A8 from "./A8.webp";

export const sampleAnimes = [A1, A2, A3, A4, A5, A6, A7, A8];
